<template>
  <FlotoDropdownPicker
    v-if="inputType === 'dropdown' || inputType === 'multiSelectDropdown'"
    v-model="selectedValue"
    :multiple="attrs['multiple'] || inputType === 'multiSelectDropdown'"
    :options="options"
    v-bind="attrs"
    v-on="listeners"
  />
  <FeedbackPicker
    v-else-if="inputType === 'feedback'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoPriorityPicker
    v-else-if="inputType === 'priority'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoStatusPicker
    v-else-if="inputType === 'taskStatus'"
    v-model="selectedValue"
    v-bind="{ ...attrs, 'module-name': $constants.TASK }"
    v-on="listeners"
  />
  <FlotoStatusPicker
    v-else-if="inputType === 'status'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoDropdownPicker
    v-else-if="
      inputType === 'slaStatus' ||
      (inputType === 'boolean' && useBooleanDropdown)
    "
    v-model="selectedValue"
    :options="[
      { text: `${$t('sla')} ${$t('violated')}`, key: true },
      { text: `${$t('sla')} ${$t('not_violated')}`, key: 'false' },
    ]"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoDropdownPicker
    v-else-if="inputType === 'mobileDeviceType'"
    v-model="selectedValue"
    :options="[
      { text: $tc('smartphone'), key: 'smartphone' },
      { text: $tc('tablet'), key: 'tablet' },
    ]"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoTimeZonePicker
    v-else-if="inputType === 'timezone'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoImpactPicker
    v-else-if="inputType === 'impact'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoUrgencyPicker
    v-else-if="inputType === 'urgency'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoLocationPicker
    v-else-if="inputType === 'location'"
    v-model="selectedValue"
    v-bind="attrs"
    :only-leaf-node-selectable="onlyLeafNodeSelectable"
    v-on="listeners"
  />
  <FlotoRolePicker
    v-else-if="inputType === 'role'"
    v-model="selectedValue"
    multiple
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoAssetTypePicker
    v-else-if="inputType === 'assetType'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoDepartmentPicker
    v-else-if="inputType === 'department'"
    v-model="selectedValue"
    v-bind="attrs"
    :only-leaf-node-selectable="onlyLeafNodeSelectable"
    v-on="listeners"
  />
  <FlotoSupportLevelPicker
    v-else-if="inputType === 'supportLevel'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoUCEscalationLevelPicker
    v-else-if="inputType === 'resolutionDueLevel'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoSourcePicker
    v-else-if="inputType === 'source'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoTechnicianPicker
    v-else-if="inputType === 'technician'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoBusinessServicePicker
    v-else-if="inputType === 'businessService'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoTaskTypePicker
    v-else-if="['task_type', 'taskType'].indexOf(inputType) >= 0"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoTagsPicker
    v-else-if="inputType === 'tags'"
    v-model="selectedValue"
    :title="attrs.placeholder"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoTagsPicker
    v-else-if="inputType === 'multiStringValue'"
    v-model="selectedValue"
    :title="attrs.placeholder || attrs.label"
    :type="inputType"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoTagsPicker
    v-else-if="['emails'].indexOf(inputType) >= 0"
    v-model="selectedValue"
    :title="$tc('email')"
    :type="TagsPickerType"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoTechnicianGroupPicker
    v-else-if="
      inputType === 'technicianGroup' || inputType === 'technician_group'
    "
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <!-- refactor requester_email to requester -->
  <!-- <FlotoRequesterEmail
    v-else-if="inputType === 'requester_email'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  /> -->
  <FlotoRequesterPicker
    v-else-if="inputType === 'requester' || inputType === 'requesterid'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoRequesterGroupPicker
    v-else-if="['requester_group', 'requesterGroup'].indexOf(inputType) >= 0"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoCategoryPicker
    v-else-if="inputType === 'category'"
    v-model="selectedValue"
    v-bind="{
      ...attrs,
      'module-name':
        attrs['module-name'] === $constants.ASSET
          ? $constants.ASSET_SOFTWARE
          : attrs['module-name'],
    }"
    :only-leaf-node-selectable="onlyLeafNodeSelectable"
    v-on="listeners"
  />
  <FlotoServiceCategoryPicker
    v-else-if="inputType === 'service_category'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <DatetimeValueInput
    v-else-if="
      (inputType === 'dateTime' || inputType === 'date') &&
      (!operator || currentDateSupportedOperators.indexOf(operator) >= 0)
    "
    v-model="selectedValue"
    :show-time="inputType === 'dateTime'"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoDatePicker
    v-else-if="inputType === 'dateTime' || inputType === 'date'"
    v-model="selectedValue"
    :show-time="inputType === 'dateTime'"
    v-bind="attrs"
    v-on="listeners"
  />
  <MSwitch v-else-if="inputType === 'boolean'" v-model="selectedValue">
    <template v-slot:checked>
      {{ $t('yes') }}
    </template>
    <template v-slot:unchecked>
      {{ $t('no') }}
    </template>
  </MSwitch>
  <FlotoEstimatedTimePicker
    v-else-if="['estimatedTime'].indexOf(inputType) >= 0"
    v-model="selectedValue"
    v-bind="attrs"
    as-input
    v-on="listeners"
  />
  <FlotoDaytimePicker
    v-else-if="inputType === 'duration'"
    :key="operator"
    v-model="selectedValue"
    v-bind="attrs"
    as-input
    v-on="listeners"
  />
  <!-- <FlotoTagsPicker
    v-else-if="inputType === 'emails'"
    v-model="selectedValue"
    type="email"
    v-bind="attrs"
    as-input
    v-on="listeners"
  /> -->
  <!-- TODO :: Change below component to time picker component -->
  <FlotoEstimatedTimePicker
    v-else-if="['notifyBeforeHours'].indexOf(inputType) >= 0"
    v-model="selectedValue.value"
    :allowed-units="['hours', 'minutes', 'days']"
    :time-unit.sync="selectedValue.timeUnit"
    v-bind="attrs"
    as-input
    v-on="listeners"
  />
  <!-- <MInputGroup
    v-else-if="inputType === 'notifyBeforeHours'"
    compact
    class="input-group-container"
  >
    <div class="flex-1">
      <MInputNumber v-model="selectedValue" type="number" :precision="0" />
    </div>
    <div
      class="px-2 py-1 bg-neutral-lightest flex items-center justify-center text-neutral border-solid border-neutral-lighter border-l-0 border-t border-b border-r rounded rounded-tl-none rounded-bl-none"
      style="display: inline-flex"
    >
      {{ $tc('minutes') }}
    </div>
  </MInputGroup> -->
  <MInput
    v-else-if="inputType === 'number'"
    v-model="selectedValue"
    type="number"
    :precision="
      attrs.precision !== undefined
        ? attrs.precision
        : ['integer', 'long'].indexOf(valueType) >= 0
        ? 0
        : 2
    "
    :min="0"
    v-bind="attrs"
  />
  <FlotoApprovalStatusPicker
    v-else-if="inputType === 'approvalStatus'"
    v-model="selectedValue"
    :excluded-status="
      attrs['module-name'] === $constants.APPROVER
        ? ['not_requested', 'pre_approved']
        : []
    "
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoRiskTypePicker
    v-else-if="
      ['riskType', 'risk_type'].indexOf(inputType) >= 0 &&
      attrs['module-name'] === $constants.CHANGE
    "
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <ProjectRiskTypePicker
    v-else-if="
      inputType === 'riskType' && attrs['module-name'] === $constants.PROJECT
    "
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoChangeTypePicker
    v-else-if="['changeType', 'change_type'].indexOf(inputType) >= 0"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoProjectTypePicker
    v-else-if="inputType === 'projectType'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoReasonTypePicker
    v-else-if="
      ['reasonType', 'reason_type'].indexOf(inputType) >= 0 &&
      attrs['module-name'] === $constants.CHANGE
    "
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <ReleaseRiskTypePicker
    v-else-if="
      inputType === 'riskType' && attrs['module-name'] === $constants.RELEASE
    "
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <ReleaseTypePicker
    v-else-if="inputType === 'releaseType'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <ReleaseReasonTypePicker
    v-else-if="
      inputType === 'reasonType' && attrs['module-name'] === $constants.RELEASE
    "
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <TechnicianFromPicker
    v-else-if="inputType === 'technician_from'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoBusinessServicePicker
    v-else-if="inputType === 'affectedServices'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoTargetEnvironmentPicker
    v-else-if="
      ['targetEnvironment', 'target_environment'].indexOf(inputType) >= 0
    "
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <AssetMovementTypePicker
    v-else-if="inputType === 'movementType'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <AssetConditionPicker
    v-else-if="inputType === 'assetCondition'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <AssetMovementStatusPicker
    v-else-if="inputType === 'assetMovementStatus'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <ProductPicker
    v-else-if="inputType === 'product'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <VendorPicker
    v-else-if="inputType === 'vendor'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <RemoteOfficePicker
    v-else-if="inputType === 'remoteOffice'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoTemplatePicker
    v-else-if="inputType === 'template'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <OriginPicker
    v-else-if="inputType === 'objectOrigin'"
    v-model="selectedValue"
    v-bind="{
      ...attrs,
      'module-name': attrs['sub-module-name']
        ? attrs['sub-module-name']
        : attrs['module-name'],
    }"
    v-on="listeners"
  />
  <FolderPicker
    v-else-if="inputType === 'objectGroup'"
    v-model="selectedValue"
    :multiple="true"
    v-on="listeners"
  />
  <GlCodePicker
    v-else-if="inputType === 'glCode'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <CompanyPicker
    v-else-if="inputType === 'company'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <CostCenterPicker
    v-else-if="inputType === 'costCenter'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <AssetGroupPicker
    v-else-if="inputType === 'assetGroup'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <SoftwareTypePicker
    v-else-if="inputType === 'softwareType'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <ManufacturerPicker
    v-else-if="inputType === 'manufacturer'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <SoftwareLicenseTypePicker
    v-else-if="inputType === 'softwareLicenceType'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <BytesPicker
    v-else-if="inputType === 'bytes'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <HertzPicker
    v-else-if="inputType === 'hz'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <AssetPickerDrawer
    v-else-if="inputType === 'softwareSuite'"
    v-model="selectedValue"
    :module-name="$constants.ASSET_SOFTWARE"
    v-bind="attrs"
    as-input
    v-on="listeners"
  />
  <AssetPickerDrawer
    v-else-if="inputType === 'hardwareAssetPicker'"
    v-model="selectedValue"
    :module-name="$constants.ASSET_HARDWARE"
    v-bind="attrs"
    as-input
    v-on="listeners"
  />
  <AssetPickerDrawer
    v-else-if="inputType === 'assetHardware'"
    v-model="selectedValue"
    :module-name="$constants.ASSET_HARDWARE"
    v-bind="attrs"
    as-input
    v-on="listeners"
  />
  <ContractStatusPicker
    v-else-if="inputType === 'contractStatus'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <ProjectStatusPicker
    v-else-if="inputType === 'projectStatus'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <ContractPicker
    v-else-if="inputType === 'contract'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <ContractTypePicker
    v-else-if="inputType === 'contractType'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <ComputerGroupPicker
    v-else-if="inputType === 'computer_group'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <ManageBaselinePicker
    v-else-if="inputType === 'associatedBaseline'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <DeploymentPolicyPicker
    v-else-if="inputType === 'deploymentPolicy'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <ArchitecturePicker
    v-else-if="inputType === 'architecture'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <IncommingEmailConfigPicker
    v-else-if="inputType === 'emailReadConfig'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <OsApplicationPicker
    v-else-if="inputType === 'osapplication'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <RegistryTemplatePicker
    v-else-if="inputType === 'registry_template'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <PackagesPicker
    v-else-if="inputType === 'packages'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <TimePickerCalender
    v-else-if="inputType === 'durationCalender'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <TreePicker
    v-else-if="inputType === 'dependent'"
    v-model="selectedValue"
    searchable
    as-input
    validate-archived-value
    :options="treePickerOptions"
    v-bind="attrs"
    v-on="listeners"
  />
  <DateVariablePicker
    v-else-if="inputType === 'dateVariable' || inputType === 'dateTimeVariable'"
    v-model="selectedValue"
    :options="options"
    :show-time="inputType === 'dateTimeVariable'"
    v-bind="attrs"
    v-on="listeners"
  />
  <CurrencyPicker
    v-else-if="inputType === 'currency'"
    v-model="selectedValue"
    value-input
    v-bind="attrs"
    v-on="listeners"
  />
  <SupportPortalPicker
    v-else-if="inputType === 'supportPortal'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <SsoConfigPicker
    v-else-if="inputType === 'ssoConfig'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoRichEditor
    v-else-if="inputType === 'richtextarea'"
    v-model="selectedValue"
    :affix-toolbar="false"
    v-bind="attrs"
    v-on="listeners"
  />
  <ServiceCatalogPicker
    v-else-if="inputType === 'service_catalog'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <LeaveTypePicker
    v-else-if="inputType === 'leaveType'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoTransitionModelPicker
    v-else-if="inputType === 'transitionModel'"
    v-model="selectedValue"
    v-bind="attrs"
    :service-catalog-id="attrs['parent-id']"
    v-on="listeners"
  />
  <TaskDependencyStagePicker
    v-else-if="inputType === 'taskDependencyStage'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <PatchTypePicker
    v-else-if="inputType === 'patchType'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <MessengerAppConfigPicker
    v-else-if="inputType === 'facebookMessengerAppConfig'"
    v-model="selectedValue"
    v-bind="attrs"
    v-on="listeners"
  />
  <FlotoModuleSelector
    v-else-if="inputType === 'approvalModuleSelector'"
    v-model="selectedValue"
    as-dropdown
    :available-modules="options.map((o) => o.key)"
    v-bind="attrs"
    v-on="listeners"
  />
  <MInput v-else v-model="selectedValue" v-bind="attrs" />
</template>

<script>
import { currentDateSupportedOperators } from '@data/operator'
import AssetMovementTypePicker from '@components/data-picker/asset-movement-type-picker'
import AssetConditionPicker from '@components/data-picker/asset-condition-picker'
import AssetMovementStatusPicker from '@components/data-picker/asset-movement-status-picker'
import ProductPicker from '@components/data-picker/product-picker'
import VendorPicker from '@components/data-picker/vendor-picker'
import OriginPicker from '@components/data-picker/origin-picker'
import FolderPicker from '@modules/knowledge/components/folder-picker'
import AssetGroupPicker from '@components/data-picker/asset-group-picker'
import SoftwareTypePicker from '@components/data-picker/software-type-picker'
import ManufacturerPicker from '@components/data-picker/manufacturer-picker'
import SoftwareLicenseTypePicker from '@components/data-picker/software-license-type-picker'
import BytesPicker from '@components/data-picker/bytes-picker'
import HertzPicker from '@components/data-picker/hertz-picker'
import AssetPickerDrawer from '@components/data-picker/asset-picker-drawer'
import ContractStatusPicker from '@components/data-picker/contract-status-picker'
import ContractPicker from '@components/data-picker/contract-picker'
import ContractTypePicker from '@components/data-picker/contract-type-picker'
import IncommingEmailConfigPicker from '@components/data-picker/incomming-email-config-picker'
import ReleaseRiskTypePicker from '@components/data-picker/release-risk-type-picker'
import ReleaseTypePicker from '@components/data-picker/release-type-picker'
import ReleaseReasonTypePicker from '@components/data-picker/release-reason-type-picker'
import TechnicianFromPicker from '@components/data-picker/technician-from-picker'
import GlCodePicker from '@components/data-picker/gl-code-picker'
import CostCenterPicker from '@components/data-picker/cost-center-picker'
import TreePicker from '@components/tree-picker/tree-picker'
import ProjectRiskTypePicker from '@components/data-picker/project-risk-type-picker'
import ProjectStatusPicker from '@components/data-picker/project-status-picker'
import DateVariablePicker from '@components/date-variable-picker'
import FeedbackPicker from '@components/data-picker/feedback-picker'
import RemoteOfficePicker from '@components/data-picker/remote-office-picker'
import ComputerGroupPicker from '@components/data-picker/computer-group-picker'
import DeploymentPolicyPicker from '@components/data-picker/deployment-policy-picker'
import ManageBaselinePicker from '@components/data-picker/manage-baseline-picker'
import OsApplicationPicker from '@components/data-picker/os-application-picker'
import ArchitecturePicker from '@components/data-picker/architecture-picker'
import RegistryTemplatePicker from '@components/data-picker/registry-template-picker'
import PackagesPicker from '@components/data-picker/packages-picker'
import TimePickerCalender from '@components/data-picker/time-picker-calender'
import CompanyPicker from '@components/data-picker/company-picker'
import DatetimeValueInput from '@components/data-picker/datetime-value-input.vue'
import CurrencyPicker from '@components/data-picker/currency-picker'
import SupportPortalPicker from '@components/data-picker/support-portal-picker'
import SsoConfigPicker from '@components/data-picker/sso-config-picker.vue'
import ServiceCatalogPicker from '@components/data-picker/service-catalog-picker.vue'
import LeaveTypePicker from '@components/data-picker/leave-type-picker.vue'
import TaskDependencyStagePicker from '@components/data-picker/task-dependency-stage-picker.vue'
import PatchTypePicker from '@components/data-picker/patch-type-picker.vue'
import MessengerAppConfigPicker from '@components/data-picker/messenger-app-config-picker.vue'

export default {
  name: 'FlotoValueInput',
  components: {
    AssetMovementTypePicker,
    AssetConditionPicker,
    AssetMovementStatusPicker,
    ProductPicker,
    VendorPicker,
    OriginPicker,
    GlCodePicker,
    AssetGroupPicker,
    CostCenterPicker,
    SoftwareTypePicker,
    FolderPicker,
    ManufacturerPicker,
    SoftwareLicenseTypePicker,
    BytesPicker,
    HertzPicker,
    AssetPickerDrawer,
    ContractStatusPicker,
    ContractPicker,
    ContractTypePicker,
    IncommingEmailConfigPicker,
    ReleaseRiskTypePicker,
    ReleaseTypePicker,
    ReleaseReasonTypePicker,
    TechnicianFromPicker,
    TreePicker,
    ProjectRiskTypePicker,
    ProjectStatusPicker,
    DateVariablePicker,
    FeedbackPicker,
    RemoteOfficePicker,
    ComputerGroupPicker,
    DeploymentPolicyPicker,
    ManageBaselinePicker,
    OsApplicationPicker,
    ArchitecturePicker,
    RegistryTemplatePicker,
    PackagesPicker,
    TimePickerCalender,
    CompanyPicker,
    DatetimeValueInput,
    CurrencyPicker,
    SupportPortalPicker,
    SsoConfigPicker,
    ServiceCatalogPicker,
    LeaveTypePicker,
    TaskDependencyStagePicker,
    PatchTypePicker,
    MessengerAppConfigPicker,
  },
  model: {
    event: 'change',
  },
  props: {
    // do not declare moduleName props here
    // eslint-disable-next-line
    asInput: { type: Boolean, default: true },
    useBooleanDropdown: { type: Boolean, default: false },
    value: {
      type: [Array, Number, String, Object, Boolean],
      default: undefined,
    },
    operator: {
      type: [String],
      default: undefined,
    },
    inputType: { type: String, default: undefined },
    valueType: { type: String, default: 'string' },
    directInput: { type: Boolean, default: false },
    options: {
      type: Array,
      default() {
        return []
      },
    },
    onlyLeafNodeSelectable: { type: Boolean, default: false },
  },
  data() {
    this.currentDateSupportedOperators = currentDateSupportedOperators
    return {}
  },
  computed: {
    selectedValue: {
      get() {
        if (
          this.inputType === 'slaStatus' &&
          (this.value === false || this.value === 'false')
        ) {
          return 'false'
        }
        return this.value
      },
      set(v) {
        this.$emit('change', v)
      },
    },
    listeners() {
      const { change, input, selected, ...listeners } = this.$listeners
      listeners.selected = this.handleSelected
      return listeners
    },
    attrs() {
      return {
        'as-input': this.asInput,
        ...this.$attrs,
      }
    },
    treePickerOptions() {
      if (this.inputType === 'dependent') {
        let options = this.options
        if (this.attrs['additional-options']) {
          options = (this.attrs['additional-options'] || []).concat(options)
        }
        return options
      }
      return []
    },
    TagsPickerType() {
      if (['start_with', 'end_with', 'contains'].indexOf(this.operator) >= 0) {
        return undefined
      }
      return 'email'
    },
  },
  methods: {
    handleSelected(valueOption) {
      if (valueOption) {
        this.$emit('selected', valueOption)
      }
    },
  },
}
</script>
